<template>
  <div>
    <!-- pic9B1F06E762D82551C6FEE3CBF40665A9.jpg -->
    <img class="w-220 h-250 rounded leftimage" src="@/assets/news-network/pod-3.jpg">
    <p>
      Most thought it would be Dallas, but Pod 3 shocked everyone by making a surprise landing in a LEGO Brand Retail Store in the Baybrook Mall, Houston.
    </p>
    <p>
      First to the scene when word of the landing was posted on bradfordrant.org was William, who raced straight up to the pod contact person and said the magic words: Pod 3!
    </p>
    <div class="clear"></div>
  </div>
</template>
